<template>
    <v-container fill-height>
        <v-layout justify-center wrap>
            <v-flex xs12>
                <material-card title="Filter">
                    <v-row>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="filter.name"
                                    label="Name"

                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-text-field
                                    v-model="filter.item_number"
                                    label="Item Number"

                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">


                            <v-btn
                                    color="blue-grey darken-4"
                                    @click="filter_results(filter)"
                            >
                                <v-icon>
                                    mdi-magnify
                                </v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </material-card>
            </v-flex>




            <v-flex md12>
                <material-card title="Products"
                               text="">
                    <template :slot="'header_actions'">
                        <v-btn rounded fab small @click="showUploadModal()">
                            <v-icon>mdi-file-upload</v-icon>
                        </v-btn>
                    </template>

                    <v-data-table :headers="headers"
                                  :items="products"
                                  :loading="loading"
                                  :loading-text="'Loading please wait'"
                                  header-props.sort-icon="mdi-menu-up"
                                  item-key="id"
                                  hide-default-footer
                                  light>

                        <template v-slot:item.actions="{ item }">
                            <v-btn small rounded text fab
                                   @click="edit_product(item.id)"
                                   v-tooltip="'Edit Product'">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.fob_price="{ item }">
                            {{ item.fob_price | currency }}
                        </template>

                        <template v-slot:item.album="{ item }">
                            <template v-if="!!item['album']">
                                <v-layout v-if="!!item['album']['files']">
                                    <template v-for="file of item['album']['files']">
                                        <v-img :src="$store.state.backend_url + file['url']"
                                               :alt="file['name']"
                                               height="40"
                                               width="40">
                                        </v-img>
                                    </template>
                                </v-layout>
                            </template>
                        </template>

                    </v-data-table>

                    <div class="text-center">
                        <v-pagination v-model="page"
                                      circle
                                      :total-visible="7"
                                      :length="max_count"></v-pagination>
                    </div>
                </material-card>
            </v-flex>
        </v-layout>

    </v-container>

</template>

<script>
    import ProductUpload from '@/components/cpanel/ProductUpload'

    export default {
        name: "Products",
        data: () => ({
            title: "Products",
            products: [],
            page:1,
            offset:0,
            limit:50,
            max_count:0,
            filter:[],
            search_url:'',
            headers: [
                {
                    text: 'Album',
                    value: 'album',
                    align: 'center',
                    custom: 'image'
                },
                {
                    text: 'Item Num',
                    value: 'item_number',
                    align: 'center'
                },
                {
                    text: 'Name',
                    value: 'name',
                    align: 'center'
                },
                {
                    text: 'FOB',
                    value: 'fob_price',
                    align: 'center'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    custom: 'actions',
                    align: 'center'
                }
            ],
            loading: true
        }),
        created () {
            this.backend_url = this.$store.state.backend_url;
            this.load_products();
        },
        watch: {
            page(val) {
                this.load_products();
            },
        },
        methods: {
            edit_product(id)
            {
                // this.$router
                    // .push({path: '/c-panel/org/product/'+id});
                window.open('/c-panel/org/product/'+id, '_blank');

            },
            load_products() {
                let offset = (this.page - 1) * this.limit;
                console.log(
                    "offset = "+offset
                );
                this.$http.get('/factories/products/?limit=50&offset='+offset+this.search_url)
                    .then((response)=>{
                        this.loading = false;
                        console.log(response.data);
                        this.max_count = parseInt(((response.data.count + 50) / this.limit));
                        this.products = response.data.results
                    })
                    .catch((error)=>{
                        this.loading = false;
                        console.log(error);
                    });
            },
            showUploadModal() {
                this.$modal.show(
                    ProductUpload,
                    {
                        text: 'This text is passed as a property'
                    },
                    {
                        adaptive: true,
                        scrollable: true,
                        reset:true,
                        height: 'auto',
                        width: '80%',
                        clickToClose: false
                    },
                    // {
                    //     'before-close': (event) => { console.log('this will be called before the modal closes'); }
                    // }
                )
            },
            seekValue(item, value) {
                if (value.includes('.')) {
                    let map = value.split('.');
                    let res = item;
                    for (let pos of map)
                        res = res[pos] ? res[pos] : res;
                    return res;
                } else return item[value];
            },
            filter_results(filter)
            {
                let search_url = '';
                for (let prop in filter) {
                    // skip loop if the property is from prototype
                    if(!filter.hasOwnProperty(prop)) continue;
                    search_url += '&'+prop+'='+filter[prop];
                }
                this.search_url = search_url;
                this.page = 1;
                this.load_products();
            }
        }


    }
</script>

<style scoped>

</style>
